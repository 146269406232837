<template>
  <div class="landing-content">
    <div class="big-card">
      <div class="left">
        <h2></h2>

        <div class="buttons">
          <img
            class="fun"
            src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/l4519mgr0r61.png"
            alt=""
          />
          <!-- <router-link to="/free" class="boldIt"
            ><button class="free">免费课程</button></router-link
          > -->
          <a href="https://doyoudo.com/free" class="boldIt"
            ><button class="free">免费课程</button></a
          >
          <a href="https://app45ddvgw38268.pc.xiaoe-tech.com" class="boldIt"
              ><button class="pay">付费课程</button></a
            >
        </div>
        <div class="links">
          <a
            href="https://space.bilibili.com/20503549"
            class="bilibili"
            target="_blank"
            >哔哩哔哩</a
          >
          <a href="#" class="wechat"
            >微信公众号<img src="/static/wechat.jpg" alt=""
          /></a>
          <a
            href="https://www.youtube.com/c/doyoudoteam"
            class="youtube"
            target="_blank"
            >YouTube</a
          >

          <router-link to="/resources">资源导航</router-link>
        </div>
      </div>
      <div class="right">
        <img
          src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/l4519mgr0izm.png"
          alt=""
          class="cloud-up"
        />
        <img src="/static/rocket.png" alt="" class="rocket" />
        <img
          src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/l4519mgr075g.png"
          alt=""
          class="cloud-down"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.landing-content {
  height: 100vh;
  width: 100vw;
  background: #cfe46e;
  padding-top: 80px;
}

.big-card {
  width: 70%;
  height: 80%;
  background: #ffffff;
  border-radius: 35px;
  margin: 0px auto;
  display: flex;
}
.left {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8% 0 7% 7%;
}

h2 {
  height: 13%;
  background: url("/static/web-logo.png") no-repeat left top;
  background-size: 30%;
}
.fun {
  width: 70%;
  margin-bottom: 6%;
}

.buttons button {
  width: 28%;
  height: 50px;
  border: none;
  border-radius: 100px;
  margin-right: 5%;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.9em;
}
.buttons .pay:hover {
  box-shadow: 0 9px 20px 0 rgba(255, 40, 40, 0.226),
    0 9px 30px 0 rgba(255, 10, 10, 0.185);
  transition: all 0.4s;
}
.buttons .free:hover {
  box-shadow: 0 9px 20px 0 rgba(159, 202, 41, 0.281),
    0 9px 30px 0 rgba(137, 192, 36, 0.288);
  transition: all 0.4s;
}

.free {
  background: #9eb80c;
}
.pay {
  background: #eb5f35;
}
.links a {
  font-size: 0.9em;
  color: #777;
  margin-right: 30px;
}
.links a:hover {
  text-decoration: underline;
  transition: all 4s;
}

.links .bilibili:hover {
  color: #4faece;
  transition: 0.3s;
}
.links .wechat:hover {
  color: #52a841;
  transition: 0.3s;
}

.wechat {
  position: relative;
}

.wechat img {
  position: absolute;
  width: 150px;
  display: none;
  left: -57%;
  top: 30px;
  box-shadow: 0 0px 40px 0 rgba(97, 97, 97, 0.226);
}

.wechat:hover img {
  display: inline-block;
  transition: all 1s;
}

.links .youtube:hover {
  color: #e22020;
  transition: 0.3s;
}
.right {
  position: relative;
  width: 40%;
}
.right .cloud-up {
  /* width: 150px; */
  width: 20%;
  position: absolute;
  top: -5%;
  left: 50%;
  animation-name: up-float;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
.right .rocket {
  width: 135%;
  position: absolute;
  top: 8%;
  left: -29%;
  animation-name: rocket-float;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
.right .cloud-down {
  width: 20%;
  position: absolute;
  bottom: -3%;
  left: -40%;
  animation-name: down-float;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes up-float {
  from {
    transform: translate(0, 0) rotate(2deg);
  }
  to {
    transform: translate(7px, 10px) rotate(-2deg);
  }
}
@keyframes rocket-float {
  from {
    transform: translate(0, 0) rotate(1deg);
  }
  to {
    transform: translate(15px, 6px) rotate(0deg);
  }
}
@keyframes down-float {
  from {
    transform: translate(5px, 20px) rotate(-3deg);
  }
  to {
    transform: translate(0, 0) rotate(3deg);
  }
}

/* ------------------------------------------------ */

@media screen and (min-width: 471px) and (max-width: 1024px) {
  .landing-content {
    padding-top: 40px;
  }
  .big-card {
    width: 80%;
    height: 45%;
    border-radius: 30px;
  }

  h2 {
    height: 10%;
    background: url("/static/web-logo.png") no-repeat left top;
    background-size: 30%;
  }

  .buttons button {
    width: 38%;
    height: 30px;
  }
  .right .cloud-up {
    width: 27%;
    top: -5%;
    left: 50%;
  }
  .right .rocket {
    width: 145%;
    top: 12%;
    left: -35%;
  }
  .right .cloud-down {
    width: 30%;
    position: absolute;
    bottom: -2%;
    left: -20%;
  }
  .links a {
    font-size: 0.8em;
    margin-right: 15px;
  }
}
@media screen and (max-width: 470px) {
  .landing-content {
    padding-top: 30px;
  }
  .big-card {
    width: 90%;
    height: 40%;
    border-radius: 20px;
  }

  h2 {
    height: 10%;
    background: url("/static/web-logo.png") no-repeat left top;
    background-size: 35%;
  }

  .buttons button {
    width: 38%;
    font-size: 0.7em;
    height: 30px;
  }

  .links a {
    font-size: 0.7em;
    margin-right: 30px;
  }
  .right .cloud-up {
    width: 25%;
    top: -5%;
    left: 50%;
  }
  .right .rocket {
    width: 140%;
    top: 12%;
    left: -40%;
  }
  .right .cloud-down {
    width: 25%;
    position: absolute;
    bottom: -2%;
    left: -20%;
  }
}
</style>